import React from "react";
import { graphql } from "gatsby";

import PropertyInvestmentSEO from "../../components/services/property-investment/propertyInvestmentSEO";
import BlogsSection from "../../components/shared/blogsSection";
import { Query } from "@graphql-types";
import TabsLink from "@components/shared/sub/tabsLink";
import { Ctas, TextButtons, Header, IframeEmbed } from "@global";
import TestimonialsCarousel from "@components/shared/testimonials";

interface Props {
  data: Query;
}

const PropertyInvestment = ({ data }: Props) => {
  if (data?.sanityPropertyInvestmentPage == null) return null;

  const {
    ctas,
    textButtons,
    title,
    header,
    topTextButtons,
    iframeEmbed,
  } = data.sanityPropertyInvestmentPage;
  const shared = data.sanitySharedSection;

  return (
    <div className="subpage-container">
      <PropertyInvestmentSEO />
      <Header title={title} headerData={header} />

      {shared?.servicesTabs && (
        <TabsLink
          tabs={shared?.servicesTabs}
          pageColor={header?.headerColor}
          slug="/services/property-investment-lending/"
        />
      )}
      {topTextButtons && <TextButtons data={topTextButtons} />}
      {ctas && <Ctas data={ctas} />}
      {iframeEmbed && <IframeEmbed data={iframeEmbed} />}
      {textButtons && <TextButtons data={textButtons} />}
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Related Blogs" category="Property Investment Lending" />
    </div>
  );
};

export default PropertyInvestment;

export const query = graphql`
  query {
    sanityPropertyInvestmentPage {
      title
      header {
        ...sanityHeader
      }
      textButtons {
        ...sanityTextButton
      }
      topTextButtons {
        ...sanityTextButton
      }
      iframeEmbed {
        ...sanityIframeEmbed
      }
      ctas {
        ...sanityCtas
      }
    }
    sanitySharedSection {
      servicesTabs {
        serviceTab {
          _key
          linkText
          url
        }
      }
    }
  }
`;
