import { Maybe, SanityServicesTabs } from "@graphql-types"
import React from "react"
import MyLink from "./myLink"
import TabsLinkStyle from "./tabsLinkStyle"
import { DesktopContainer, MobileContainer } from "@util/standard"
import { Select } from "antd"

interface Props {
  tabs: SanityServicesTabs
  pageColor: Maybe<string> | undefined
  slug?: string
}

const TabsLink = ({ tabs, pageColor, slug }: Props) => {
  if (tabs?.serviceTab == null) return null
  const { Option } = Select

  const currentTab = tabs.serviceTab.find(tab => tab?.url === slug)
  return (
    <TabsLinkStyle pageColor={pageColor}>
      <DesktopContainer>
        <div className="links-wrapper">
          {tabs.serviceTab.map(tab => {
            if (tab == null || tab.url == null) return null

            return (
              <MyLink key={tab._key} url={tab.url}>
                {tab.linkText}
              </MyLink>
            )
          })}
        </div>
      </DesktopContainer>
      <MobileContainer margin="30px auto" width="80%">
        <Select style={{ width: "100%" }} value={currentTab?.linkText}>
          {tabs.serviceTab.map(tab => {
            if (tab == null || tab.url == null) return null

            return (
              <Option value={tab.linkText}>
                <MyLink key={tab._key} url={tab.url}>
                  {tab.linkText}
                </MyLink>
              </Option>
            )
          })}
        </Select>
      </MobileContainer>
    </TabsLinkStyle>
  )
}

export default TabsLink
