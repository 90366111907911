import { Maybe } from "@graphql-types"
import { colors } from "@util/constants"
import { Color } from "@util/types"
import styled from "styled-components"

export default styled.section<{ pageColor?: Maybe<string> | undefined }>`
  display: block;
  width: 80%;
  position: relative;
  margin: 0 auto;
  max-width: 1380px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  .links-wrapper {
    position: absolute;
    left: 0px;
    width: 100%;
    display: flex;
    transform: translateY(-100%);

    @media (max-width: 600px) {
      position: relative;
      left: unset;
      transform: unset;
      margin-left: -1px;
      align-items: center;
      width: calc(100% + 1px);
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    a {
      display: block;
      color: white;
      padding: 1rem 2rem;
      margin-left: 1rem;
      background-color: rgba(255, 255, 255, 0.2);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      &:first-child {
        margin-left: 0px;
      }
      @media (max-width: 600px) {
        width: 35%;
        color: var(--red);
        margin-left: 0;
        text-align: center;
        border-radius: 0;
        flex-grow: 1;
        height: 4rem;
        width: 100%;
        border-bottom: 1px solid var(--red);
        border-left: 1px solid var(--red);
      }

      @media (max-width: 340px) {
        font-size: 1rem;
      }

      &:hover,
      &.active {
        background-color: var(--white);
        color: ${props =>
          props.pageColor ? colors[props.pageColor as Color] : "var(--red)"};

        @media (max-width: 600px) {
          background-color: var(--red);
          color: var(--white);
        }
      }
    }
  }
`
